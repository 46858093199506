import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Overview/index.ts";
import { FaArchive as LegacyIcon, FaCloud as ApiReferenceIcon, FaClipboardList as ReleaseNotesIcon, FaCloud as WebServicesIcon, FaDatabase as DatabasesIcon, FaMapMarkerAlt as GeolocateAnIPIcon, FaPencilAlt, FaQuestionCircle as FaqIcon, FaRocket, FaSync as UpdateDatabasesIcon } from 'react-icons/fa';
import GeoIPOverview from './_geoip-overview.mdx';
import GeoLiteOverview from './_geolite-overview.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LinkGroupContainer = makeShortcode("LinkGroupContainer");
const LinkGroup = makeShortcode("LinkGroup");
const LinkGroupCard = makeShortcode("LinkGroupCard");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`MaxMind’s GeoIP2 and GeoLite2 IP intelligence products and services are used to
discover information about a specific IP address. We provide free and paid web
services, subscription-based downloadable databases, and free downloadable
databases.`}</p>
    <LinkGroupContainer mdxType="LinkGroupContainer">
      <LinkGroup heading="Guides" mdxType="LinkGroup">
        <LinkGroupCard description="Use MaxMind GeoIP databases, web services, or JavaScript client to geolocate an IP address." heading="Geolocate an IP" icon={GeolocateAnIPIcon} to="/geoip/geolocate-an-ip" mdxType="LinkGroupCard" />
        <LinkGroupCard description="Automatically keep your MaxMind GeoIP database up to date using the GeoIP Update program." heading="Updating Databases" icon={UpdateDatabasesIcon} to="/geoip/updating-databases" mdxType="LinkGroupCard" />
      </LinkGroup>
    </LinkGroupContainer>
    <div {...{
      "id": "toc-product-documentation"
    }}><h2 {...{
        "id": "product-documentation",
        "parentName": "div"
      }}>{`Product Documentation`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Find detailed API documentation for our binary databases, data specifications,
and information about client APIs and our REST API for our web services.`}</p>
      <GeoIPOverview mdxType="GeoIPOverview" />
      <GeoLiteOverview mdxType="GeoLiteOverview" />
      <LinkGroupContainer mdxType="LinkGroupContainer">
        <LinkGroup isCompact heading="Resources" mdxType="LinkGroup">
          <LinkGroupCard description="Detailed documentation of GeoIP2 and GeoLite2 web service usage." heading="Web Service Documentation" icon={ApiReferenceIcon} to="/geoip/docs/web-services" mdxType="LinkGroupCard" />
          <LinkGroupCard description="Detailed documentation of GeoIP2 and GeoLite2 database usage." heading="Database Documentation" icon={DatabasesIcon} to="/geoip/docs/databases" mdxType="LinkGroupCard" />
          <LinkGroupCard description="Review GeoIP2 release notes to understand changes to the GeoIP2 API." heading="Release Notes" icon={ReleaseNotesIcon} to="/geoip/release-notes" mdxType="LinkGroupCard" />
          <LinkGroupCard description="Consult our knowledge base for articles about using GeoIP." heading="Knowledge Base" icon={FaqIcon} to="https://support.maxmind.com/hc/en-us/categories/1260801446650-GeoIP2-and-GeoLite2" mdxType="LinkGroupCard" />
        </LinkGroup>
      </LinkGroupContainer></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      